<template>
  <div class="container">
    <div v-show="uaParser.isIOS" class="swiper ios-banners">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="/assets/images/banner1.png" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/images/banner2.png" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/images/banner3.png" />
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>

    <div v-show="!uaParser.isIOS" class="swiper android-banners">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="/assets/images/banner1.png" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/images/banner2.png" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/images/banner3.png" />
        </div>
      </div>
    </div>

    <div v-if="uaParser.isIOS" class="ios_wrapper">
      <img src="/assets/images/bg_ios.jpg" />

      <div class="get-button-s" @click="downloadMobileConfig">
        <img src="/assets/images/get_s.jpg" />
      </div>
      <div class="problem-button" @click="onClickProblem">
        <img src="/assets/images/problem-button.jpg" />
      </div>
      <div class="get-button-big" @click="downloadMobileConfig">
        <img src="/assets/images/get_big.jpg" />
      </div>
    </div>
    <div v-else class="android-wrapper">
      <img src="/assets/images/android1.jpg" />
      <div @click="downloadPackage">
        <img src="/assets/images/android2.jpg" />
      </div>
      <img src="/assets/images/android3.jpg" />
      <img src="/assets/images/bg_android2.jpg" />
      <img src="/assets/images/bg_android3.jpg" />
      <img src="/assets/images/bg_android4.jpg" />
      <img src="/assets/images/bg_android5.jpg" />
    </div>

    <van-overlay :duration="0" :show="showPromptDialog" @click="cancelOverlay">
      <div class="dialog">
        <div @click.stop>
          <div v-if="showTeaching" class="teaching" @click="cancelOverlay">
            <img src="/assets/images/teaching.jpg" />
          </div>
          <!-- <div v-else-if="showProblem" class="problem-wrapper">
            <van-button color="rgb(52,120,255)" class="view-button" @click="viewWorkaround">View workaround</van-button>
            <div class="dialog-close-btn" @click="cancelOverlay"></div>
          </div> -->
          <div v-else class="workaround">
            <carousel ref="carouselCompoent" :items="items" />
            <div class="dialog-close-btn" @click="cancelOverlay"></div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- IOS下且非Safari浏览器，显示拷贝绘画 -->
    <van-overlay :duration="0" :show="showCopyDialog">
      <div class="dialog">
        <div class="forward-wrapper">
          <div class="forward-top">
            <img src="/assets/images/open_in_sf.jpg">
          </div>
          <div class="forward-bottom">
            <div class="input-wrapper">
              <div class="input-left">
                {{ url }}
              </div>
              <div class="input-right" @click="doCopy"></div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'

import { Button, Overlay } from 'vant'
import Carousel from './Carousel.vue'

// 
const Teaching = 1
const Problem = 2
const Resolve = 3

export default {
  name: 'Download',
  components: {
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    Carousel
  },
  data() {
    return {
      url: '',
      uaParser: {},
      showPromptDialog: false,
      showCopyDialog: false,
      status: Teaching,

      items: [
        '/assets/images/p1.jpg',
        '/assets/images/p2.jpg',
        '/assets/images/p3.jpg',
        '/assets/images/p4.jpg',
      ],

      iosBanners: null,
      androidBanners: null
    }
  },
  computed: {
    showTeaching() {
      return this.status === Teaching
    },
    showProblem() {
      return this.status === Problem
    },
    showResolve() {
      return this.status === Resolve
    }
  },
  created() {
    this.$nextTick(() => {
      this.url = window.location.href
      this.uaParser = this.parseDevice()
    })
  },
  mounted() {
    this.$nextTick(() => {
      if (this.uaParser.isIOS) {
        // IOS环境下的判断
        this.initIOSBanners()
        if (!this.uaParser.isSafari) {
          this.showCopyDialog = true
        }
        // if (this.uaParser.isSafari) {
        //   window.addEventListener('load', () => {
        //     setTimeout(() => {
        //       this.status = Teaching
        //       this.showPromptDialog = true
        //       // 下载文件
        //       setTimeout(() => {
        //         this.downloadMobileConfig()
        //       }, 500)
        //     }, 1000)
        //   })
        // } else {
        //   this.showCopyDialog = true
        // }
      } else {
        this.initAndroidBanners()
        // setTimeout(() => {
        //   this.downloadPackage()
        // }, 500)
      }
    })
  },
  methods: {
    parseDevice() {
      const userAgent = navigator.userAgent;
      const isIOS = /iPhone|iPad|iPod/.test(userAgent)
      const isSafari = /CriOS|FxiOS/.test(userAgent) ? false : /Version\/[\d.]+.*Safari/.test(userAgent);
      return {
        isIOS,
        isSafari
      }
    },
    downloadMobileConfig() {
      // const url = '/assets/juwa.mobileconfig'
      // const url = '/assets/coolbet.mobileconfig'
      // const url = '/assets/LuckyLucky.mobileconfig'
      const url = '/assets/SuperDragon2.mobileconfig'
      this.download(url)

      setTimeout(() => {
        this.status = Teaching
        this.showPromptDialog = true
      }, 1000)
    },
    downloadPackage() {
      // const url = 'https://update.hot-bet.net/juwaplus/JUWAPlus-release-v3.apk'
      // const url = 'https://update.hot-bet.net/coolbet/CoolBet-release-v4.apk'
      const url = 'https://update.hot-bet.net/superdragon/SuperDragon-release-v4.apk'
      this.download(url)
    },
    download(url) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click();
      document.body.removeChild(link)
    },
    onClickProblem() {
      this.status = Resolve
      this.showPromptDialog = true
    },
    viewWorkaround() {
      this.status = Resolve
    },
    cancelOverlay() {
      this.showPromptDialog = false
      this.status = Teaching
    },
    initIOSBanners() {
      if (!this.iosBanners) {
        this.iosBanners = new Swiper('.ios-banners', {
          loop: true,
          autoplay: {
            delay: 1000,
            disableOnInteraction: false
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          observer: true,
          observeParents: true
        })
        console.log(this.iosBanners)
      }
    },
    initAndroidBanners() {
      if (!this.androidBanners) {
        this.androidBanners = new Swiper('.android-banners', {
          loop: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          observer: true,
          observeParents: true
        })
        console.log(this.androidBanners)
      }
    },
    doCopy() {
      this.$copyText(this.url).then(() => {
        alert('Link copied successfully')
      }, () => {
        alert('Copy failed, please open the download address using Safari browser.')
      });
    }
  }
}
</script>

<style lang="less">
.ios-banners {
  position: absolute;
  top: 382px;
  left: 15px;

  height: 175px;
  width: 378px;

  border-radius: 20px;
  border: 1px solid #cdc;
  overflow: hidden;
}
.swiper-button-next, .swiper-button-prev {
  color: #fff; 
  z-index: 1;
}
.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 28px;
}

.container {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;

  img {
    width: 100%;
    display: block;
  }

  .ios_wrapper {
    width: 100%;
  }

  .get-button-s {
    display: block;
    
    width: 140px;
    height: 35px;
        
    position: absolute;
    top: 112px;
    left: 144px;
    border-radius: 24px;
  }
  .problem-button {
    width: 28px;
    height: 28px;

    position: absolute;
    right: 21px;
    top: 116px;
  }
  .get-button-big {
    display: block;

    width: 255px;
    height: 52px;
    border-radius: 24px;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 53px;
  }
  
  .dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .teaching {
    width: 351px;
    border-radius: 10px;
  }
  .teaching img {
    border-radius: 10px;
  }
  
  // 702 529
  .problem-wrapper {
    width: 351px;
    height: 264px;

    background: no-repeat url('/assets/images/problem.jpg');
    background-size: 100% 100%;

    position: relative;
    left: 0;
    top: 0;
  }

  .view-button {
    height: 32px;

    font-weight: 500;
    border-radius: 14px;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 14px;
  }

  .workaround {
    width: 351px;
    height: 366px;
    border-radius: 12px;

    position: relative;
    left: 0;
    top: 0;
  }
  .workaround img {
    border-radius: 12px;
  }

  .forward-wrapper {
    width: 351px;
    height: 246px;
    border-radius: 12px; 
  }

  .forward-top {
    height: 158px;
  }
  .forward-top img {
    border-radius: 12px 12px 0 0;
  }

  .forward-bottom {
    height: 85px;
    background: #fff;
    border-radius: 0 0 12px 12px;

    padding: 0 40px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .input-wrapper {
    width: 100%;
    height: 36px;

    display: flex;
  }
  .input-left{    
    flex: 0 0 auto;

    width: 205px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-weight: 600;
    color: #000;

    padding-left: 22px;
    overflow: hidden;

    border-radius: 20px 0 0 20px;;
    background-color: rgb(235, 235, 235);
  }
  .input-right {
    width: 62px;
    flex: 0 0 auto;
    border-radius: 0 20px 20px 0;
    
    background: no-repeat url('/assets/images/copy.jpg');
    background-size: 100% 100%;
  }
  .dialog-close-btn {
    width: 22px;
    height: 22px;

    position: absolute;
    right: 8px;
    top: 8px;

    z-index: 50;
  }

  .android-wrapper {
    width: 100%;
    a {
      display: block;
    }
  }
  .android-banners {
    position: absolute;
    top: 720px;
    left: 50%;
    transform: translateX(-50%);

    width: 380px;
    overflow: hidden;
    border-radius: 8px;
  }
}


</style>